* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.custom-button {
    font-size: 15px;
    color: rgb(238, 238, 238);
    text-decoration: none;
    font-weight: 400;
    opacity: 1;
    background: none;
    cursor: pointer;
    display: inline-block;
    font-family: 'Cascadia', sans-serif;
    transition: opacity 0.3s ease, background-color 0.1s ease, color 0.1s ease;
    padding: 10px 20px;
    margin-right: 10px;
    border-radius: 5px;
    border: none;
    outline: none;
}

.custom-button:hover {
    color: var(--accent-color);
}

.custom-link {
    text-decoration: none;
    color: inherit;
}

.resumeButton {
    color: var(--accent-color);
    border: 2px solid var(--accent-color);
    margin-left: 10px;
}

.resumeButton:hover {
    background-color: rgba(74, 163, 237, 0.2);
    color: var(--accent-color);
    border: 2px solid var(--accent-color);
}

.reachOutButton {
    color: var(--accent-color);
    border: 2px solid var(--accent-color);
    margin-left: 10px;
}

.reachOutButton:hover {
    background-color: rgba(74, 163, 237, 0.2);
    color: var(--accent-color);
    border: 2px solid var(--accent-color);
}

.connectWithMe:hover {
    background-color: rgba(74, 163, 237, 0.2);
    color: var(--accent-color);
    border: 2px solid var(--accent-color);
}