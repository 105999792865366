@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
    font-family: 'Poppins', sans-serif, 'Cascadia';
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.projectsSection {
    height: 90rem;
    margin-top: 14rem;
    background-color: rgb(27, 27, 27);
    text-align: center;
    box-sizing: border-box;
    opacity: 0;
    transform: translateY(0px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;
}

.project {
    align-items: center;
    margin: 0;
    max-width: 60rem;
    margin-right: 2rem;
    margin-left: 2rem;
}


.projectsSection.visible {
    opacity: 1;
    transform: translateY(0);
}

.somethings {
    font-weight: 600;
    font-size: clamp(1.4rem, 1.2239rem + 0.7512vw, 1.9rem);
    color: rgb(237, 237, 237);
    font-family: 'Poppins', sans-serif;
    display: inline-block;
    padding: 0.5em 1em;
    margin: 20px 0;
    border-radius: 10px;
}

.project {
    align-items: center;
    margin-bottom: 5rem;
}

.twoDivs {
    display: flex;
    flex-direction: row;
}

.projectImage {
    height: 17.5rem;
    width: auto;
    border-radius: 10px;
    background-color: #51a9f0;
    filter: brightness(0.55);
    transition: filter 0.3s ease, background-color 0.3s ease;
    z-index: -100;
}

.projectImage:hover {
    filter: none;
    background-color: transparent;
    cursor: pointer;
}

.descriptionSection {
    margin-left: -7rem;
    margin-top: 0rem;
    text-decoration: none;
}

.descriptionSectionREVERSE {
    margin-right: -7rem;
    margin-top: 2rem;
    text-decoration: none;
}

.description {
    font-size: 0.95rem;
    background-color: rgb(59, 56, 56);
    color: rgb(237, 237, 237);
    text-align: right;
    padding: 1rem;
    border-radius: 2px;
    box-sizing: border-box;
    z-index: 2;
    position: relative;
    box-shadow: -8px 9px 12px rgb(19, 19, 19);
}

.featured {
    font-weight: 550;
    font-size: 1.0rem;
    color: var(--accent-color);
    text-align: right;
    font-family: 'Cascadia';
    text-decoration: none !important;
    margin-bottom: 0.2rem;
    z-index: 2;
    margin-top: 1rem;
}

.projectTitle {
    font-weight: 550;
    font-size: 1.6rem;
    color: rgb(237, 237, 237);
    text-align: right;
    font-family: 'Poppins';
    text-decoration: none !important;
    z-index: 2;
    transition: color 0.3s ease;
}

.tools {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    justify-content: flex-end;
}

.tool {
    font-size: 0.83rem;
    color: rgb(196, 196, 196);
    font-family: 'Cascadia';
}


.toolsREVERSE {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    text-align: left;
}

.twoDivs.reverse {
    flex-direction: row-reverse;
}

.descriptionREVERSE {
    font-size: 0.95rem;
    background-color: rgb(59, 56, 56);
    color: rgb(237, 237, 237);
    text-align: left;
    padding: 1rem;
    border-radius: 2px;
    box-sizing: border-box;
    z-index: 1;
    position: relative;
    box-shadow: -8px 9px 12px rgb(19, 19, 19);
}

.projectTitleREVERSE {
    font-weight: 550;
    font-size: 1.6rem;
    color: rgb(237, 237, 237);
    text-align: left;
    font-family: 'Poppins';
    text-decoration: none !important;
}

.featuredREVERSE {
    font-weight: 550;
    font-size: 1.0rem;
    color: var(--accent-color);
    text-align: left;
    font-family: 'Cascadia';
    text-decoration: none !important;
    margin-bottom: 0.2rem;
}

.projectLinks {
    color: rgb(237, 237, 237);
    transition: color 0.3s ease, transform 0.3s ease;
    margin-left: 0.8rem;
    transition: color 0.3s ease;
}

.projectLinks:hover {
    color: var(--accent-color);
}

.projectLinksREVERSE:hover {
    color: var(--accent-color);
}

.projectLinkIcons {
    display: flex-end;
    margin-top: 1rem;
    text-align: right;
}

.projectLinksREVERSE {
    color: rgb(237, 237, 237);
    transition: color 0.3s ease, transform 0.3s ease;
    margin-right: 0.8rem;
    transition: color 0.3s ease;
}

.projectLinkIconsREVERSE {
    display: flex;
    margin-top: 1rem;
    text-align: right;
}

@media (max-width: 941px) {

    .projectsSection {
        margin-top: 0rem;
        height: 70rem;
    }

    .project {
        margin-bottom: 5rem;
    }

    .twoDivs {
        flex-direction: column;
        align-items: center;
        margin: 0;
    }

    .projectImage {
        height: 20rem;
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
        position: relative;
        overflow: hidden;
        filter: brightness(0.2);
    }

    .descriptionSection {
        position: absolute;
        font-size: 0.4rem;
        color: rgb(237, 237, 237);
        padding-left: clamp(3rem, -2.2817rem + 22.5352vw, 18rem);
        padding-right: clamp(3rem, -2.2817rem + 22.5352vw, 18rem);
        margin-right: 0;
        margin-top: 2rem;
        margin-left: 0;
        width: 100%;
        text-align: center;
        z-index: 2;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        background-color: none;
    }

    .description {
        font-size: 0.95rem;
        color: rgb(237, 237, 237);
        text-align: left;
        padding: 0.8rem;
        border-radius: 5px;
        width: auto;
        margin: 0 auto;
        box-shadow: -8px 9px 12px rgb(19, 19, 19);
    }

    .descriptionSectionREVERSE {
        position: absolute;
        font-size: 0.4rem;
        color: rgb(237, 237, 237);
        padding-left: clamp(3rem, -2.2817rem + 22.5352vw, 18rem);
        padding-right: clamp(3rem, -2.2817rem + 22.5352vw, 18rem);
        margin-right: 0;
        margin-top: 2rem;
        width: 100%;
        text-align: center;
        z-index: 2;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        background-color: none;
    }

    .projectTitle {
        text-align: left;
        font-weight: 600;
        font-size: 1.5rem;
    }

    .featured {
        text-align: left;
        margin-bottom: 0rem;
        margin-top: 0rem;
    }

    .projectTitleREVERSE {
        text-align: left;
        font-weight: 600;
        font-size: 1.5rem;
    }

    .featured {
        font-size: 1rem;
        color: var(--accent-color);
    }

    .tools {
        gap: 1rem;
        margin-top: 0.5rem;
        font-size: 0.3rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .toolsREVERSE {
        gap: 1rem;
        margin-top: 0.5rem;
        font-size: 0.3rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .projectLinkIcons {
        display: flex;
        flex-direction: row;
        justify-content: flex;
        margin-left: 0rem;
        margin-top: 0.3rem;
        text-align: right;
    }

    .projectLinkIconsREVERSE {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 1rem;
        text-align: left;
    }

    .somethings {
        font-weight: 600;
        font-size: clamp(1.4rem, 1.2239rem + 0.7512vw, 1.9rem);
        color: rgb(237, 237, 237);
        font-family: 'Poppins', sans-serif;
        display: inline-block;
        padding: 0.5em 1em;
        margin: 10px 0;
        border-radius: 10px;
        margin-bottom: 0rem;
    }
}